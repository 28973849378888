import React, { useState } from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

// components
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Map from "../components/map/map"

const Projects = ({ data }) => {
  const [projectFilter, setProjectFilter] = useState(null)

  const filterPoints = data?.allContentfulProject?.edges?.filter(node => {
    return projectFilter === node.node.service.name || !projectFilter
  })

  const points = filterPoints.map(node => {
    return {
      type: "Feature",
      properties: {
        cluster: false,
        id: node.node.contentful_id,
        name: node.node.name,
        service: node.node.service.name,
        size: node.node.size,
        industry: node.node.industry,
        certification: node.node.certification,
      },
      geometry: {
        type: "Point",
        coordinates: [node.node.location.lon, node.node.location.lat],
      },
    }
  })

  const handleFilterClick = name => {
    if (projectFilter === name) {
      setProjectFilter(null)
    } else {
      setProjectFilter(name)
    }
  }

  return (
    <Layout
      headerLang={data?.langQuery?.siteMetadata?.es.header}
      footerLang={data?.langQuery?.siteMetadata?.es.footer}
      langType="es"
    >
      <SEO
        title="Projects"
        description="EEE has taken part in projects spanning many countries and climates."
      />

      <section className="max-w-1024 mx-auto px-2x">
        <div className="pt-32">
          <Link to="/es/">
            <p className="font-body text-darkerBlue bg-lightGreen px-4 py-2 text-lg mr-8 underline font-bold">
              Tell us about your next project
            </p>
          </Link>
          <Map points={points} />
          <div className="mt-6">
            <h3 className="font-heading">Filter Projects</h3>
            <div className="flex flex-wrap">
              <button
                className={`transform duration-300 roudned-full font-body  px-4 py-1 bg-gray-300 cursor-pointer mr-6 mb-6 ${
                  projectFilter === "Building Commissioning"
                    ? "bg-darkerBlue text-white"
                    : ""
                }`}
                onClick={() => handleFilterClick("Building Commissioning")}
              >
                Building Commissioning
              </button>
              <button
                className={`transform duration-300 roudned-full font-body  px-4 py-1 bg-gray-300 cursor-pointer mr-6 mb-6 ${
                  projectFilter === "Energy Modeling"
                    ? "bg-darkerBlue text-white"
                    : ""
                }`}
                onClick={() => handleFilterClick("Energy Modeling")}
              >
                Energy Modeling
              </button>
              <button
                className={`transform duration-300 roudned-full font-body  px-4 py-1 bg-gray-300 cursor-pointer mr-6 mb-6 ${
                  projectFilter === "Energy Retrofits"
                    ? "bg-darkerBlue text-white"
                    : ""
                }`}
                onClick={() => handleFilterClick("Energy Retrofits")}
              >
                Energy Retrofits
              </button>
              <button
                className={`transform duration-300 roudned-full font-body  px-4 py-1 bg-gray-300 cursor-pointer mr-6 mb-6 ${
                  projectFilter === "CFD Modeling"
                    ? "bg-darkerBlue text-white"
                    : ""
                }`}
                onClick={() => handleFilterClick("CFD Modeling")}
              >
                CFD Modeling
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Projects

export const query = graphql`
  query {
    langQuery: site {
      siteMetadata {
        es {
          header {
            commissioning
            modeling
            retrofits
            cfd
            projects
            contact
          }
          footer {
            contact
            navItems
          }
        }
      }
    }
    allContentfulProject(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          contentful_id
          name
          location {
            lat
            lon
          }
          service {
            name
          }
          size
          industry
          certification
        }
      }
    }
  }
`
